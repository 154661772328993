import api from '../tools/api';

export const getProductCategories = (
	filter = null
) => api.request(
	'products',
	'get-product-categories',
	filter
);
export const findModelApi = (
	query,
	limit = 50,
	successHandler = (e) => {},
	errorHandler = (e) => {}
) => api.request(
	'products',
	'find-model',
	{query, limit}
).then(successHandler).catch(errorHandler);

export const getModelsByIds = (
	ids,
	successHandler = (e) => {},
	errorHandler = (e) => {}
) => api.request(
	'products',
	'get-models',
	{ids}
).then(successHandler).catch(errorHandler);

export const findProductCategoriesApi = (
	query,
	limit = 50,
	successHandler = (e) => {},
	errorHandler = (e) => {}
) => api.request(
	'products',
	'find-product-categories',
	{query, limit}
).then(successHandler).catch(errorHandler);

export const getProductCategoriesByCode = (
	code,
	successHandler = (e) => {},
	errorHandler = (e) => {}
) => api.request(
	'products',
	'get-product-categories',
	{code}
).then(successHandler).catch(errorHandler);

export const getProductMachines = (
	data,
	successHandler = (e) => {},
	errorHandler = (e) => {}
) => api.request(
	'products',
	'get-product-machines',
	data
).then(successHandler).catch(errorHandler);

export const getManufacturers = (
	data,
	successHandler = (e) => {},
	errorHandler = (e) => {}
) => api.request(
	'products',
	'get-manufacturers',
	data
).then(successHandler).catch(errorHandler);

export const getProductCategoryFeatures = (
	data,
	successHandler = (e) => {},
	errorHandler = (e) => {}
) => api.request(
	'products',
	'get-product-category-features',
    data
).then(successHandler).catch(errorHandler);

export const GetCatalogCategoryTree = data => api.request(
    'products',
    'get-catalog-category-tree',
    data
);

export const findModelsByCatalogCategory = data => api.request(
    'products',
    'find-models-by-catalog-category',
    data
);

export const findModelsCategory = data => api.request(
    'products',
    'find-models-by-category',
    data
);
